import { styled } from 'styled-components'
import { FormControl, MenuItem } from '@mui/material'

export const StyledFormControl = styled(FormControl)`
  text-transform: capitalize;
`

export const StyledMenuItem = styled(MenuItem)`
  text-transform: capitalize;
`
