import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GlobalRole } from '../../../configs/constants'

interface AttributesObj {
  group_id: string
  group_name: string
}

export interface UserObj {
  id: string
  createdTimestamp: number
  username: string
  enabled: boolean
  totp: boolean
  emailVerified: boolean
  firstName: string
  lastName: string
  email: string
  attributes: AttributesObj
  disableableCredentialTypes: []
  requiredActions: []
  notBefore: number
  access: unknown
  globalRole: GlobalRole | null
  portalAdmin: boolean
}

export interface UpdateUserObj {
  userId: string
  enabled?: boolean
  globalRole?: GlobalRole | null
  portalAdmin?: boolean
}

type UserlistState = UserObj[]

export const reducerName = 'userlist'

export const initialUserlistState: UserlistState = []

const userlistSlice = createSlice({
  name: reducerName,
  initialState: initialUserlistState,
  reducers: {
    setUserlist(state, action: PayloadAction<UserObj[]>) {
      return [...action.payload]
    },
    setUserActivationStatus(state, action: PayloadAction<UpdateUserObj>) {
      const updatePayload = action.payload
      const user = state.find(({ id }) => id === updatePayload.userId)
      if (user !== undefined) {
        user.enabled = updatePayload.enabled ?? user.enabled
        user.globalRole = updatePayload.globalRole ?? user.globalRole
        user.portalAdmin = updatePayload.portalAdmin ?? user.portalAdmin
      }
    },
  },
})

/** export the userlist reducer actions */
export const {
  setUserlist: setUserlistAction,
  setUserActivationStatus: setUserActivationStatusAction,
} = userlistSlice.actions

/** export the userlist default reducer */
export default userlistSlice.reducer
