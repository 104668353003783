import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import {
  ERROR_NOTIFICATION_OPTIONS,
  SUCCESS_NOTIFICATION_OPTIONS,
  TogglePropertyType,
} from '../../configs/constants'
import { updateUserPropertiesAction } from '../../store/slices/userlist/actions'
import { UserObj } from '../../store/slices/userlist/slice'
import { useAppDispatch } from '../../store/hooks'

interface ToggleSwitchObj {
  user: UserObj
  property: TogglePropertyType
  value: boolean
}

const ToggleSwitch = ({ user, property, value }: ToggleSwitchObj) => {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const [open, setOpen] = useState(false)

  const handleClickOpen = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setOpen(true)
  }

  const CheckBoxMap: Record<TogglePropertyType, boolean> = {
    [TogglePropertyType.enabled]: user.enabled,
    [TogglePropertyType.portalAdmin]: user.portalAdmin,
  }

  const DialogContentTextMap: Record<TogglePropertyType, string> = {
    [TogglePropertyType.enabled]: `Do you really want to ${
      user.enabled ? 'disable' : 'enable'
    } the user "${user.email}"?`,
    [TogglePropertyType.portalAdmin]: `Do you really want to ${
      user.portalAdmin ? 'disable' : 'enable'
    } the kelda portal admin status of the user "${user.email}"?`,
  }

  const handleConfirm = () => {
    dispatch(
      updateUserPropertiesAction({
        userId: user.id,
        [property]: value,
        postSuccessHandler: () => {
          enqueueSnackbar(
            'User updated successfully!',
            SUCCESS_NOTIFICATION_OPTIONS
          )
        },
        errorHandler: () => {
          enqueueSnackbar('Failed to update user!', ERROR_NOTIFICATION_OPTIONS)
        },
      })
    )
    handleClose()
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Switch
        checked={CheckBoxMap[property]}
        onChange={handleClickOpen}
        color="secondary"
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Confirm the action
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {DialogContentTextMap[property]}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm}>Yes</Button>
          <Button onClick={handleClose}>No</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ToggleSwitch
