import { OptionsObject } from 'notistack'

export const COMPANY_NAME = 'KELDA DYNAMICS'

/** External Links */

function getExternalLinks() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (window as any).ENV?.REACT_APP_EXTERNAL_LINKS
}

export const EXTERNAL_LINKS = JSON.parse(
  getExternalLinks() || process.env.REACT_APP_EXTERNAL_LINKS || '[]'
)

/** Optional Modules */

function getOptionalModules() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (window as any).ENV?.REACT_APP_KP_OPTIONAL_MODULES
}

const KP_OPTIONAL_MODULES = JSON.parse(
  getOptionalModules() || process.env.REACT_APP_KP_OPTIONAL_MODULES || '[]'
)

export enum KPOptionalModules {
  org = 'org',
  accessControl = 'access-control',
}

export enum AuthenticationTypes {
  googleAuthenticator = 'google-authenticator',
}

export enum TableOptions {
  header = 'header',
  accessorKey = 'accessorKey',
  accessorFn = 'accessorFn',
  cell = 'cell',
  id = 'id',
}

export enum TableOptionValues {
  id = 'id',
  name = 'name',
  description = 'description',
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
}

export enum GlobalRole {
  globalAdmin = 'global-admin',
  admin = 'admin',
  user = 'user',
}

export enum TogglePropertyType {
  enabled = 'enabled',
  portalAdmin = 'portalAdmin',
}

export const SHOULD_INCLUDE_ORGANIZATIONS = KP_OPTIONAL_MODULES.includes(
  KPOptionalModules.org
)

export const SHOULD_INCLUDE_ACCESS_CONTROL = KP_OPTIONAL_MODULES.includes(
  KPOptionalModules.accessControl
)

/** Users Table Optional Columns */

function getUserlistOptionalColumns() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (window as any).ENV?.REACT_APP_KP_USERS_TABLE_OPTIONAL_COLUMNS
}

const KP_USERS_TABLE_OPTIONAL_COLUMNS = JSON.parse(
  getUserlistOptionalColumns() ||
    process.env.REACT_APP_KP_USERS_TABLE_OPTIONAL_COLUMNS ||
    '[]'
)

export enum KPUsersTableOptionalColumns {
  organization = 'organization',
  globalRole = 'globalRole',
}

export const SHOULD_INCLUDE_ORGANIZATION_COLUMN =
  KP_USERS_TABLE_OPTIONAL_COLUMNS.includes(
    KPUsersTableOptionalColumns.organization
  )

export const SHOULD_INCLUDE_GLOBAL_ROLE_COLUMN =
  KP_USERS_TABLE_OPTIONAL_COLUMNS.includes(
    KPUsersTableOptionalColumns.globalRole
  )

/** keycloak */
export const KEYCLOAK_INIT_OPTIONS = { onLoad: 'check-sso' }
export const KEYCLOAK_KP_ADMIN_ROLE =
  process.env.REACT_APP_KEYCLOAK_KP_ADMIN_ROLE || 'kp_admin'

/** keycloak server config */

function getKeycloakServerConfig() {
  return {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    KEYCLOAK_URI: (window as any).ENV?.REACT_APP_KEYCLOAK_URI,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    KEYCLOAK_REALM: (window as any).ENV?.REACT_APP_KEYCLOAK_REALM,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    KEYCLOAK_CLIENT_ID: (window as any).ENV?.REACT_APP_KEYCLOAK_CLIENT_ID,
  }
}

const keycloakConfig = getKeycloakServerConfig()

export const KEYCLOAK_ENDPOINT =
  keycloakConfig.KEYCLOAK_URI ||
  process.env.REACT_APP_KEYCLOAK_URI ||
  'http://localhost:8080/auth'
export const KEYCLOAK_REALM =
  keycloakConfig.KEYCLOAK_REALM ||
  process.env.REACT_APP_KEYCLOAK_REALM ||
  'master'
export const KEYCLOAK_CLIENT_ID =
  keycloakConfig.KEYCLOAK_CLIENT_ID ||
  process.env.REACT_APP_KEYCLOAK_CLIENT_ID ||
  'demo-client-id'

/** notifications */
const anchorOrigin: OptionsObject['anchorOrigin'] = {
  vertical: 'top',
  horizontal: 'center',
}
export const DEFAULT_NOTIFICATION_OPTIONS: OptionsObject = {
  variant: 'default',
  anchorOrigin,
}

export const SUCCESS_NOTIFICATION_OPTIONS: OptionsObject = {
  variant: 'success',
  anchorOrigin,
}

export const ERROR_NOTIFICATION_OPTIONS: OptionsObject = {
  variant: 'error',
  anchorOrigin,
}

export const INFO_NOTIFICATION_OPTIONS: OptionsObject = {
  variant: 'info',
  anchorOrigin,
}

export const WARNING_NOTIFICATION_OPTIONS: OptionsObject = {
  variant: 'warning',
  anchorOrigin,
}

export const ADMIN_ROLE = 'admin'
export const USER_ROLE = 'user'

export const DATETIME_FORMAT = 'DD.MM.YYYY, HH:mm'
