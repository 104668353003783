import {
  StyledContentWrapper,
  StyledTableBodyWrapper,
  StyledTableFooterWrapper,
  StyledTableHeaderWrapper,
  StyledTableTitle,
} from './elements'
import { useEffect, useMemo, useState } from 'react'
import { Table } from '../../components/Table'
import { Button, InputAdornment, Pagination, TextField } from '@mui/material'
import { Add, SearchOutlined } from '@mui/icons-material'
import { selectInvitations } from '../../store/slices/invitation/slice'
import { INVITATION_COLUMNS_CONFIG } from './tableConfig'
import { Modal } from '../../components/Modal'
import { InvitationCreateForm } from '../../components/InvitationCreateForm'
import { useModal } from '../../hooks/useModal'
import { fetchInvitationsAction } from '../../store/slices/invitation/actions'
import { ERROR_NOTIFICATION_OPTIONS } from '../../configs/constants'
import { useSnackbar } from 'notistack'
import { useAppDispatch, useAppSelector } from '../../store/hooks'

const ROWS_LIMIT = 20
const FETCH_ERROR_MESSAGE = 'An error occurred while fetching invitations!'

const Invitations = (): JSX.Element => {
  const invitationList = useAppSelector(selectInvitations)
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: any = useMemo(() => INVITATION_COLUMNS_CONFIG, [])
  const [search, setSearch] = useState<string>('')
  const [total, setTotal] = useState<number>(0)
  const [pageNumber, setPageNumber] = useState<number>(1)

  useEffect(() => {
    dispatch(
      fetchInvitationsAction({
        offset: (pageNumber - 1) * ROWS_LIMIT,
        limit: ROWS_LIMIT,
        search: search,
        postSuccessHandler: (total) => {
          setTotal(total)
        },
        errorHandler: () => {
          enqueueSnackbar(FETCH_ERROR_MESSAGE, ERROR_NOTIFICATION_OPTIONS)
        },
      })
    )
  }, [dispatch, enqueueSnackbar, pageNumber, search])

  const { modalStatus, openModalHandler, closeModalHandler } = useModal()

  const postCreationHander = () => {
    dispatch(
      fetchInvitationsAction({
        offset: (pageNumber - 1) * ROWS_LIMIT,
        limit: ROWS_LIMIT,
        search: search,
        postSuccessHandler: (total) => {
          setTotal(total)
        },
        errorHandler: () => {
          enqueueSnackbar(FETCH_ERROR_MESSAGE, ERROR_NOTIFICATION_OPTIONS)
        },
      })
    )
    closeModalHandler()
  }

  const pageChangeHandler = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(
      fetchInvitationsAction({
        offset: (value - 1) * ROWS_LIMIT,
        limit: ROWS_LIMIT,
        search: search,
        postSuccessHandler: (total) => {
          setPageNumber(value)
          setTotal(total)
        },
        errorHandler: () => {
          enqueueSnackbar(FETCH_ERROR_MESSAGE, ERROR_NOTIFICATION_OPTIONS)
        },
      })
    )
  }

  const searchChangeHandler = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearch(event.target.value)
    dispatch(
      fetchInvitationsAction({
        offset: 0,
        limit: ROWS_LIMIT,
        search: event.target.value,
        postSuccessHandler: (total) => {
          setPageNumber(1)
          setTotal(total)
        },
        errorHandler: () => {
          enqueueSnackbar(FETCH_ERROR_MESSAGE, ERROR_NOTIFICATION_OPTIONS)
        },
      })
    )
  }

  return (
    <StyledContentWrapper>
      <StyledTableHeaderWrapper>
        <StyledTableTitle>Invitations</StyledTableTitle>
        <TextField
          placeholder="Search"
          sx={{ mr: 1 }}
          size="small"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
          onChange={searchChangeHandler}
        />
        <Button
          color="secondary"
          variant="contained"
          startIcon={<Add />}
          onClick={openModalHandler}
          data-testid={'button-new-invitation'}
        >
          New Invite
        </Button>
        <Modal
          open={modalStatus}
          onCancel={closeModalHandler}
          title={'New Invitation'}
          content={
            <InvitationCreateForm
              postSubmitActionHandler={postCreationHander}
            />
          }
        />
      </StyledTableHeaderWrapper>

      <StyledTableBodyWrapper>
        <Table columns={columns} data={invitationList} />
        <StyledTableFooterWrapper>
          <Pagination
            count={Math.ceil(total / ROWS_LIMIT)}
            color="secondary"
            page={pageNumber}
            onChange={pageChangeHandler}
          />
        </StyledTableFooterWrapper>
      </StyledTableBodyWrapper>
    </StyledContentWrapper>
  )
}

export default Invitations
