import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { AddEntityUserButton } from '../AddEntityUserButton'
import { RemoveEntityUserButton } from '../RemoveEntityUserButton'
import { AddRemoveEntityUserPayload } from '../../store/slices/models'
import { TableOptions, TableOptionValues } from '../../configs/constants'

export const getRemoveRoleUsersColumnsConfig = (
  entityId: string,
  entityName: string,
  entityAction: ActionCreatorWithPayload<AddRemoveEntityUserPayload, string>,
  postActionHandler: () => void
) => {
  return [
    {
      [TableOptions.header]: 'First Name',
      [TableOptions.accessorKey]: TableOptionValues.firstName,
    },
    {
      [TableOptions.header]: 'Last Name',
      [TableOptions.accessorKey]: TableOptionValues.lastName,
    },
    {
      [TableOptions.header]: 'Email',
      [TableOptions.accessorKey]: TableOptionValues.email,
    },
    {
      [TableOptions.header]: 'Action',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [TableOptions.cell]: ({ row }: any) => {
        return (
          <RemoveEntityUserButton
            userId={row.original.id}
            userEmail={row.original.email}
            entityId={entityId}
            entityName={entityName}
            entityAction={entityAction}
            postActionHandler={postActionHandler}
          />
        )
      },
    },
  ]
}

export const getAddRoleUsersColumnsConfig = (
  entityId: string,
  entityName: string,
  entityAction: ActionCreatorWithPayload<AddRemoveEntityUserPayload, string>,
  postActionHandler: () => void
) => {
  return [
    {
      [TableOptions.header]: 'First Name',
      [TableOptions.accessorKey]: TableOptionValues.firstName,
    },
    {
      [TableOptions.header]: 'Last Name',
      [TableOptions.accessorKey]: TableOptionValues.lastName,
    },
    {
      [TableOptions.header]: 'Email',
      [TableOptions.accessorKey]: TableOptionValues.email,
    },
    {
      [TableOptions.header]: 'Action',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [TableOptions.cell]: ({ row }: any) => {
        return (
          <AddEntityUserButton
            userId={row.original.id}
            userEmail={row.original.email}
            entityId={entityId}
            entityName={entityName}
            entityAction={entityAction}
            postActionHandler={postActionHandler}
          />
        )
      },
    },
  ]
}
