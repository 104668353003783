import { ServerUserResponse } from './models'
import { mapGroupFromServer } from '../groups/mappings'
import { mapRoleFromServer } from '../roles/mappings'

export const MapUserFromServer = (user: ServerUserResponse) => {
  const {
    first_name,
    last_name,
    keycloak_id,
    global_role,
    portal_admin,
    tfa_id,
    groups,
    roles,
    ...rest
  } = user
  return {
    firstName: first_name,
    lastName: last_name,
    keycloakId: keycloak_id,
    globalRole: global_role,
    portalAdmin: portal_admin,
    tfaId: tfa_id,
    groups: groups.map((group) => mapGroupFromServer(group)),
    roles: roles.map((role) => mapRoleFromServer(role)),
    ...rest,
  }
}
