import React, { useEffect, useState } from 'react'
import { Select, SelectChangeEvent } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import {
  ERROR_NOTIFICATION_OPTIONS,
  GlobalRole,
  SUCCESS_NOTIFICATION_OPTIONS,
} from '../../configs/constants'
import { updateUserPropertiesAction } from '../../store/slices/userlist/actions'
import { useAppDispatch } from '../../store/hooks'
import { StyledFormControl, StyledMenuItem } from './elements'

interface GlobalRoleCellProps {
  userId: string
  role: GlobalRole | null
}

const GlobalRoleCell = ({ userId, role }: GlobalRoleCellProps) => {
  const dispatch = useAppDispatch()
  const [globalRole, setGlobalRole] = useState<GlobalRole | null>(role)

  useEffect(() => {
    setGlobalRole(role)
  }, [role])

  const handleChange = (event: SelectChangeEvent) => {
    const targetValue = event.target.value as GlobalRole
    dispatch(
      updateUserPropertiesAction({
        userId: userId,
        globalRole: targetValue === undefined ? null : targetValue,
        postSuccessHandler: () => {
          setGlobalRole(targetValue)
          enqueueSnackbar(
            'User updated successfully!',
            SUCCESS_NOTIFICATION_OPTIONS
          )
        },
        errorHandler: () => {
          enqueueSnackbar('Failed to update user!', ERROR_NOTIFICATION_OPTIONS)
        },
      })
    )
  }
  return (
    <StyledFormControl sx={{ minWidth: 120 }} size="small">
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={globalRole || undefined}
        onChange={handleChange}
        displayEmpty
        renderValue={(selected) => {
          return selected ?? <em>Not set</em>
        }}
      >
        <StyledMenuItem value={undefined}>
          <em>Unset</em>
        </StyledMenuItem>
        {Object.values(GlobalRole).map((role) => (
          <StyledMenuItem value={role} key={role}>
            {role}
          </StyledMenuItem>
        ))}
      </Select>
    </StyledFormControl>
  )
}

export default GlobalRoleCell
